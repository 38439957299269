import { t } from "@/i18n-js/instance";

export const GENERAL_ERROR = {
  message: t("services.paywalls.failures.internal.generic"),
};

export const STRIPE_CONNECTION_ERROR = {
  message: t("paywall_checkout.form.errors.stripe_connection_failed"),
};

export const PIX_PAYMENT_EXPIRED = {
  message: t("paywall_checkout.form.errors.pix_payment.time_expired"),
  error_details: "PIX_PAYMENT_EXPIRED",
};

export const PIX_MODAL_CLOSED = {
  message: t("paywall_checkout.form.errors.pix_payment.pix_modal_closed"),
  error_details: "PIX_MODAL_CLOSED",
};

export const getReferral = () => window.Rewardful?.referral;

export const DEFAULT_ERROR = {
  disablePayButton: true,
  type: "danger",
  existingAccount: false,
  hasAlreadyPurchased: false,
  message: null,
};

interface BuildErrorMessageOptions {
  loginUrl?: string;
  resetPasswordUrl?: string;
}

export const buildErrorMessage = (
  error: any,
  { loginUrl, resetPasswordUrl }: BuildErrorMessageOptions = {},
) => {
  const errors: Record<string, any> = {
    USER_LOGIN_REQUIRED: {
      disablePayButton: false,
      type: "warning",
      existingAccount: true,
      message: t("paywall_checkout.form.errors.existing_account", {
        sign_in_url: loginUrl,
        reset_password_url: resetPasswordUrl,
      }),
    },
    INVALID_COUPON: {
      message: error.message,
    },
    PIX_PAYMENT_EXPIRED,
    PIX_MODAL_CLOSED,
  };

  return errors[error.error_details] || GENERAL_ERROR;
};

interface BuildPayButtonLabel {
  isPaywallActive: boolean;
  selectedPrice: any;
  preview: any;
}

export const buildPayButtonLabel = ({
  isPaywallActive,
  selectedPrice,
  preview,
}: BuildPayButtonLabel) => {
  if (!isPaywallActive || !selectedPrice) {
    return t("paywall_checkout.form.buttons.draft_pay");
  }
  if (selectedPrice?.trial_enabled) {
    if (selectedPrice?.trial_requires_upfront_payment) {
      return t("paywall_checkout.form.buttons.paid_trial");
    }
    return t("paywall_checkout.form.buttons.trial");
  }
  if (preview?.amount_due_now === 0 && selectedPrice?.display_interval) {
    return t("paywall_checkout.form.buttons.subscribe");
  }
  if (preview?.amount_due_now === 0 && !selectedPrice?.display_interval) {
    return t("paywall_checkout.form.buttons.continue");
  }
  if (preview?.amount_due_now > 0) {
    return `${t("paywall_checkout.form.buttons.pay")} ${
      preview.formatted_amount_due_now
    }`;
  }
  return `${t("paywall_checkout.form.buttons.pay")} ${
    selectedPrice.display_amount_with_currency
  }`;
};

export const PERMITTED_QUERY_PARAMETERS = [
  "price_id",
  "coupon_code",
  "affiliate_code",
  "event_id",
];

export const getValidQueryParameters = () => {
  const queryParameters = new URLSearchParams(window.location.search);

  queryParameters.forEach((_value, key) => {
    if (!PERMITTED_QUERY_PARAMETERS.includes(key)) {
      queryParameters.delete(key);
    }
  });

  return queryParameters;
};
