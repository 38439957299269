import { t } from "@/i18n-js/instance";
import { formatPaywallCurrencyAmount } from "@/react/helpers/paywalls/currencies";

const PAGINATION_LIMIT_THRESHOLD = 1000;
export const getPaginationLimitThreshold = value =>
  Math.min(Number(value) || 1, PAGINATION_LIMIT_THRESHOLD);

export const callTriggerWhenNotSelectingText = trigger => () => {
  if (window.getSelection().toString() === "") {
    trigger();
  }
};

export const mapCurrencyAmountStrToInt = currencyAmountStr =>
  parseInt(String(currencyAmountStr || "0").replace(/[^\d\\-]/g, ""));
export const mapCurrencyAmountStrToDecimal = currencyAmountStr =>
  parseFloat(String(currencyAmountStr || "0").replace(/[^\d\\.\\-]/g, ""));

export const buildTransformInputValueToCurrencyAmountStrFunc = (
  currency,
  options = {},
) => {
  const defaultOpts = {
    amountInCents: true,
  };
  const opts = { ...defaultOpts, ...options };

  return inputValue => {
    let amount;
    if (opts.amountInCents) {
      amount = mapCurrencyAmountStrToInt(inputValue);
    } else {
      amount = mapCurrencyAmountStrToDecimal(inputValue);
    }
    if (!isNaN(amount)) {
      let maxValueAllowed = currency.max_charge_amount;
      if (!opts.amountInCents) {
        maxValueAllowed = Math.round(maxValueAllowed / 100);
      }
      amount = Math.min(amount, maxValueAllowed);

      return formatPaywallCurrencyAmount(currency, amount, {
        amountInCents: opts.amountInCents,
        includeCurrencyCode: false,
        forceMaxFractionDigits: true,
      });
    }
  };
};

export const formatDisplayAmountString = (
  currency,
  interval,
  intervalCount,
  amount,
) => {
  let formattedAmount = formatPaywallCurrencyAmount(currency, amount);
  if (!interval) {
    return `${formattedAmount} ${t(
      "settings.paywall_prices.onetime_price_text",
    )}`;
  }

  formattedAmount += " / ";
  formattedAmount += t(`settings.paywall_prices.interval_labels.${interval}`, {
    count: intervalCount,
  });
  return formattedAmount;
};

export const queryParamsSanitizer = (
  { tab, page, search_key },
  defaultTabs = {},
) => ({
  tab: Object.values(defaultTabs).includes(tab) ? tab : undefined,
  page: isNaN(Number(page)) ? 1 : Number(page),
  search_key,
});
