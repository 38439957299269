import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import type { Dispatch, SetStateAction } from "react";
import { noop } from "lodash";
import type { CommunityMemberBillingInfo } from "@/react/types";
import type { UsePaywallCheckoutReturn } from "./usePaywallCheckout";
import { initialPaymentError, usePaywallCheckout } from "./usePaywallCheckout";

interface PaywallCheckoutContext extends UsePaywallCheckoutReturn {
  hasAlreadyPurchased: boolean;
  checkoutConfirmationUrl: string;
  children: ReactNode;
  communityRootUrl: string;
  areCouponsEnabled: boolean;
  creditCard: any;
  currentCommunity: any;
  currentCommunityMember: any;
  currentCommunityMemberPaymentProcessorSessionClientSecret?: string;
  currentUser: any;
  isMemberAddressRequired: boolean;
  memberBillingInfo?: CommunityMemberBillingInfo;
  loginUrl: string;
  resetPasswordUrl: string;
  paywall: any;
  shouldShowTaxIdForm: boolean;
  isTaxIdMandatory: boolean;
  isBrazilianAccountAndProcessorStripeBr: boolean;
  isPaywallDigitalWalletEnabled: boolean;
  isBuyNowPayLaterEnabled: boolean; // This is a feature flag
  isBuyNowPayLaterAllowed: boolean; // This is a state that can be dynamically turned on/off
  setIsBuyNowPayLaterAllowed: (value: boolean) => void;
  isMemberWithSavedPaymentMethods: boolean;
  isMemberUsingSavedPaymentMethod: boolean;
  setIsMemberUsingSavedPaymentMethod: (value: boolean) => void;
  paymentMethodsSupported: string[];
  paymentElementKey: string;
  authProviderProperties: {
    name?: string;
    is_sso_authentication_required?: boolean;
  };
  disabledPaymentMethods: string[];
  setDisabledPaymentMethods: (value: string[]) => void;
  setPaymentMethodsSupported?: Dispatch<SetStateAction<string[]>>;
  setPaywallFailure: Dispatch<any>;
}

const initialState: PaywallCheckoutContext = {
  hasAlreadyPurchased: false,
  applyCouponCode: noop,
  checkoutConfirmationUrl: "",
  children: null,
  clearCouponCode: noop,
  communityRootUrl: "",
  areCouponsEnabled: false,
  creditCard: {},
  currentCommunity: {},
  currentCommunityMember: {},
  currentUser: {},
  enableTaxIdCollection: noop,
  getSelectedPrice: () => ({ trial_enabled: false }),
  isMemberAddressRequired: false,
  memberBillingInfo: undefined,
  isTaxIdCollectionEnabled: false,
  loginUrl: "",
  resetPasswordUrl: "",
  paymentError: initialPaymentError,
  paywall: {},
  previewMutation: {},
  setMemberAddressAttributes: noop,
  setPaymentError: noop,
  setSelectedPrice: noop,
  isCardInfoComplete: false,
  setIsCardInfoComplete: noop,
  isCardInfoValid: false,
  isPaywallActive: false,
  isCardInfoRequired: false,
  isSetupIntentRequired: false,
  arePostCreateActionsRequired: false,
  handleError: noop,
  isProcessingPayment: false,
  setIsProcessingPayment: noop,
  amountDue: 0,
  shouldShowTaxIdForm: false,
  isTaxIdMandatory: false,
  isBrazilianAccountAndProcessorStripeBr: false,
  isPaywallDigitalWalletEnabled: false,
  isBuyNowPayLaterEnabled: false,
  isBuyNowPayLaterAllowed: true,
  setIsBuyNowPayLaterAllowed: noop,
  stripePaymentMethodType: "card",
  setStripePaymentMethodType: noop,
  isMemberWithSavedPaymentMethods: false,
  isMemberUsingSavedPaymentMethod: false,
  setIsMemberUsingSavedPaymentMethod: noop,
  paywallFailure: null,
  setPaywallFailure: noop,
  paymentMethodsSupported: ["card"],
  setPaymentMethodsSupported: noop,
  paymentElementKey: "",
  authProviderProperties: {},
  disabledPaymentMethods: [],
  setDisabledPaymentMethods: noop,
};

const PaywallCheckoutContext = createContext(initialState);

PaywallCheckoutContext.displayName = "PaywallCheckoutContext";

interface ProviderProps {
  children: ReactNode;
  currentCommunity: any;
  currentUser: any;
  loginUrl: string;
  resetPasswordUrl: string;
  communityRootUrl: string;
  currentCommunityMember: any;
  hasAlreadyPurchased: boolean;
  isMemberAddressRequired: boolean;
  isTaxIdMandatory: boolean;
  isBrazilianAccountAndProcessorStripeBr: boolean;
  isPaywallDigitalWalletEnabled: boolean;
  paywall: any;
  isMemberWithSavedPaymentMethods: boolean;
  paymentMethodsSupported: string[];
  paymentElementKey: string;
  setPaymentMethodsSupported?: Dispatch<SetStateAction<string[]>>;
  areCouponsEnabled: boolean;
  checkoutConfirmationUrl: string;
  currentCommunityMemberPaymentProcessorSessionClientSecret?: string;
  currentCommunityMemberPaymentProcessorSessionCustomerId?: string;
  memberBillingInfo: any;
  processorId: string;
  processorKey: string;
  shouldShowTaxIdForm: boolean;
  disabledPaymentMethods: string[];
  setDisabledPaymentMethods: (value: string[]) => void;
}

export const PaywallCheckoutContextProvider = (props: ProviderProps) => {
  const {
    children,
    isMemberAddressRequired,
    paywall,
    isTaxIdMandatory,
    isMemberWithSavedPaymentMethods,
    paymentElementKey,
    disabledPaymentMethods,
    setDisabledPaymentMethods,
  } = props;

  const {
    applyCouponCode,
    clearCouponCode,
    enableTaxIdCollection,
    getSelectedPrice,
    isTaxIdCollectionEnabled,
    paymentError,
    previewMutation,
    setMemberAddressAttributes,
    setPaymentError,
    setSelectedPrice,
    isCardInfoComplete,
    setIsCardInfoComplete,
    isCardInfoValid,
    isPaywallActive,
    isCardInfoRequired,
    isSetupIntentRequired,
    arePostCreateActionsRequired,
    handleError,
    isProcessingPayment,
    setIsProcessingPayment,
    isMemberUsingSavedPaymentMethod,
    setIsMemberUsingSavedPaymentMethod,
    ...restContext
  } = usePaywallCheckout({
    isMemberAddressRequired,
    isTaxIdMandatory,
    paywall,
    isMemberWithSavedPaymentMethods,
  });

  const value: PaywallCheckoutContext = {
    ...initialState,
    ...props,
    applyCouponCode,
    clearCouponCode,
    enableTaxIdCollection,
    getSelectedPrice,
    isTaxIdCollectionEnabled,
    paymentError,
    previewMutation,
    setMemberAddressAttributes,
    setPaymentError,
    setSelectedPrice,
    isCardInfoComplete,
    setIsCardInfoComplete,
    isCardInfoValid,
    isPaywallActive,
    isCardInfoRequired,
    isSetupIntentRequired,
    arePostCreateActionsRequired,
    handleError,
    isProcessingPayment,
    setIsProcessingPayment,
    isMemberUsingSavedPaymentMethod,
    setIsMemberUsingSavedPaymentMethod,
    paymentElementKey,
    disabledPaymentMethods,
    setDisabledPaymentMethods,
    ...restContext,
  };

  return (
    <PaywallCheckoutContext.Provider value={value}>
      {children}
    </PaywallCheckoutContext.Provider>
  );
};

export const usePaywallCheckoutContext = () =>
  useContext(PaywallCheckoutContext);
