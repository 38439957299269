import { isCard } from "@circle-react/helpers/paywallCheckoutHelpers";
import { usePunditUserContext } from "../punditUserContext";

export const useCardInfo = (
  paywall: any,
  selectedPrice: any,
  payment_method_type: any,
  isCardInfoComplete: boolean,
  isMemberWithSavedPaymentMethods: boolean,
  isMemberUsingSavedPaymentMethod: boolean,
  isFreeForever: boolean,
) => {
  const { currentCommunitySettings } = usePunditUserContext();
  const isPaywalls3dsAuthEnabled = Boolean(
    currentCommunitySettings?.paywalls_3ds_auth_enabled,
  );
  const isCardInfoRequired =
    (!selectedPrice.trial_enabled ||
      selectedPrice.trial_requires_upfront_payment ||
      paywall.trial_requires_card) &&
    isCard(payment_method_type) &&
    !isFreeForever;
  const isCardInfoValid = !isCardInfoRequired || isCardInfoComplete;

  const isFreeTrialThatRequiresCard =
    selectedPrice.trial_enabled &&
    isCard(payment_method_type) &&
    paywall.trial_requires_card &&
    !selectedPrice.trial_requires_upfront_payment;

  const isSetupIntentRequired =
    !isPaywalls3dsAuthEnabled ||
    (!isMemberWithSavedPaymentMethods && isFreeTrialThatRequiresCard) ||
    (isMemberWithSavedPaymentMethods && !isMemberUsingSavedPaymentMethod);

  const arePostCreateActionsRequired = isPaywalls3dsAuthEnabled;

  return {
    isCardInfoRequired,
    isCardInfoValid,
    isSetupIntentRequired,
    arePostCreateActionsRequired,
  };
};
