export const retrievePaywallCheckoutHostname = () =>
  `${window.location.origin}/checkout/`;

export const copyPaywallCheckoutUrlWithPrefix = (
  checkoutPath: string,
  clipboardFallbackSelector: string,
): void => {
  const checkoutUrl = `${retrievePaywallCheckoutHostname()}${checkoutPath}`;

  if (navigator?.clipboard) {
    void navigator.clipboard.writeText(checkoutUrl);
  } else {
    // clipboard fallback
    const tempInput = document.createElement("input");

    tempInput.style.position = "absolute";
    tempInput.style.left = "-1000px";
    tempInput.style.top = "-1000px";
    tempInput.value = checkoutUrl;

    const fallbackElement = document.querySelector(clipboardFallbackSelector);
    if (fallbackElement) {
      fallbackElement.appendChild(tempInput);
    }

    tempInput.focus();
    tempInput.setSelectionRange(0, tempInput.value.length);

    document.execCommand("copy");
  }
};

export const PixLimit = {
  MINIMUM: 50,
  MAXIMUM: 1500000,
};

export const PaymentMethodType = {
  CARD: "card",
  PIX: "pix",
  GOOGLE_PAY: "google_pay",
  APPLE_PAY: "apple_pay",
  LINK: "link",
  KLARNA: "klarna",
  AFTERPAY_CLEARPAY: "afterpay_clearpay",
  AFFIRM: "affirm",
} as const;

// https://docs.stripe.com/payments/buy-now-pay-later#transaction-support
// Amounts in USD or approximate local equivalents. We're overshooting a bit so conversion fluctuations don't cause issues.
const MINIMUM_AMOUNT_AFFIRM = {
  USD: 5000,
  CAD: 7500,
};

const MAXIMUM_AMOUNT_AFFIRM = {
  USD: 3000000,
  CAD: 4300000,
};

const MINIMUM_AMOUNT_AFTERPAY_CLEARPAY = {
  USD: 100,
  CAD: 100,
  GBP: 100,
  AUD: 100,
  NZD: 100,
};

const MAXIMUM_AMOUNT_AFTERPAY_CLEARPAY = {
  USD: 400000,
  CAD: 200000,
  GBP: 120000,
  AUD: 200000,
  NZD: 200000,
};

const MINIMUM_AMOUNT_KLARNA = {
  USD: 0,
  AUD: 0,
  CAD: 0,
  CHF: 100,
  CZK: 0,
  DKK: 1,
  EUR: 10,
  GBP: 0,
  NOK: 0,
  NZD: 1000,
  PLN: 0,
  RON: 0,
  SEK: 100,
};

const MAXIMUM_AMOUNT_KLARNA = {
  USD: 1000000,
  CAD: 1750000,
  EUR: 400000, // Todo mickeytgl: EUR limits depend on the EU country. Create a more granular breakdown
  DKK: 10000000,
  NOK: 10000000,
  SEK: 10000000,
  RON: 2000000,
  PLN: 2000000,
  GBP: 500000,
  CZK: 10000000,
  AUD: 400000,
  NZD: 200000,
  CHF: 158500,
};

const PAYMENT_METHOD_VALUES: readonly string[] = [
  PaymentMethodType.CARD,
  PaymentMethodType.PIX,
  PaymentMethodType.GOOGLE_PAY,
  PaymentMethodType.APPLE_PAY,
  PaymentMethodType.LINK,
  PaymentMethodType.KLARNA,
  PaymentMethodType.AFTERPAY_CLEARPAY,
  PaymentMethodType.AFFIRM,
];

const isValidPaymentMethodType = (
  paymentMethodType: string,
): paymentMethodType is (typeof PaymentMethodType)[keyof typeof PaymentMethodType] =>
  PAYMENT_METHOD_VALUES.includes(paymentMethodType);

const normalizePaymentMethodType = (
  paymentMethodType: string,
): PaymentMethodType | null => {
  const normalized = paymentMethodType.toLowerCase();
  return isValidPaymentMethodType(normalized) ? normalized : null;
};

export const isPaymentMethod = (
  paymentMethodType: string,
  method: PaymentMethodType,
) => normalizePaymentMethodType(paymentMethodType) === method;

type PaymentMethodType =
  (typeof PaymentMethodType)[keyof typeof PaymentMethodType];
export const isPix = (paymentMethodType: string) =>
  isPaymentMethod(paymentMethodType, PaymentMethodType.PIX);
export const isCard = (paymentMethodType: string) =>
  isPaymentMethod(paymentMethodType, PaymentMethodType.CARD);

export const isLink = (paymentMethodType: string) =>
  isPaymentMethod(paymentMethodType, PaymentMethodType.LINK);
export const isGooglePay = (paymentMethodType: string) =>
  isPaymentMethod(paymentMethodType, PaymentMethodType.GOOGLE_PAY);
export const isApplePay = (paymentMethodType: string) =>
  isPaymentMethod(paymentMethodType, PaymentMethodType.APPLE_PAY);
export const isKlarna = (paymentMethodType: string) =>
  isPaymentMethod(paymentMethodType, PaymentMethodType.KLARNA);
export const isAfterpayClearpay = (paymentMethodType: string) =>
  isPaymentMethod(paymentMethodType, PaymentMethodType.AFTERPAY_CLEARPAY);
export const isAffirm = (paymentMethodType: string) =>
  isPaymentMethod(paymentMethodType, PaymentMethodType.AFFIRM);

export const isDigitalWallet = (paymentMethodType: string) =>
  isGooglePay(paymentMethodType) || isApplePay(paymentMethodType);

export const isBuyNowPayLater = (paymentMethodType: string) =>
  isKlarna(paymentMethodType) ||
  isAfterpayClearpay(paymentMethodType) ||
  isAffirm(paymentMethodType);

export const cannotPurchaseAgain = (
  hasAlreadyPurchased: boolean,
  paywall: any,
) => hasAlreadyPurchased && !paywall?.can_be_repurchased;

const isKlarnaCurrency = (
  currency: string,
): currency is keyof typeof MINIMUM_AMOUNT_KLARNA =>
  Object.keys(MINIMUM_AMOUNT_KLARNA).includes(currency);

const isAfterpayClearpayCurrency = (
  currency: string,
): currency is keyof typeof MINIMUM_AMOUNT_AFTERPAY_CLEARPAY =>
  Object.keys(MINIMUM_AMOUNT_AFTERPAY_CLEARPAY).includes(currency);

const isAffirmCurrency = (
  currency: string,
): currency is keyof typeof MINIMUM_AMOUNT_AFFIRM =>
  Object.keys(MINIMUM_AMOUNT_AFFIRM).includes(currency);

export const isKlarnaSupported = (currency: string, amount: number) =>
  isKlarnaCurrency(currency) &&
  amount >= MINIMUM_AMOUNT_KLARNA[currency] &&
  amount <= MAXIMUM_AMOUNT_KLARNA[currency];

export const isAfterpayClearpaySupported = (currency: string, amount: number) =>
  isAfterpayClearpayCurrency(currency) &&
  amount >= MINIMUM_AMOUNT_AFTERPAY_CLEARPAY[currency] &&
  amount <= MAXIMUM_AMOUNT_AFTERPAY_CLEARPAY[currency];

export const isAffirmSupported = (currency: string, amount: number) =>
  isAffirmCurrency(currency) &&
  amount >= MINIMUM_AMOUNT_AFFIRM[currency] &&
  amount <= MAXIMUM_AMOUNT_AFFIRM[currency];
