import { useCallback, useEffect, useState } from "react";
import type { Dispatch, SetStateAction } from "react";
import { useFormContext } from "react-hook-form";
import { useMutation } from "react-query";
import type { UseMutationResult } from "react-query";
import { paywallCheckoutApi } from "@circle-react/api/paywallCheckoutApi";
import { isValidTaxIdValue } from "@circle-react/components/Paywalls/Checkout/CheckoutForm/CheckoutFormTaxIdField/taxIdTypes";
import { DEFAULT_ERROR } from "@circle-react/components/Paywalls/Checkout/helpers";
import { getCurrentCircleLocale } from "@circle-react/helpers/localeHelpers";
import { isPix } from "@circle-react/helpers/paywallCheckoutHelpers";
import { isActive } from "@circle-react/helpers/paywallHelpers";
import type {
  PaywallCheckoutPreview,
  PaywallFailure,
} from "@circle-react/types";
import { useCardInfo } from "./useCardInfo";
import { useMemberAddressAttributes } from "./useMemberAddressAttributes";

export interface PaymentError {
  disablePayButton: boolean;
  existingAccount?: boolean;
  message: string;
  type: string;
}

export const initialPaymentError: PaymentError = {
  disablePayButton: false,
  existingAccount: false,
  message: "",
  type: "",
};

export interface UsePaywallCheckoutProps {
  isMemberAddressRequired: boolean;
  paywall: any;
  isTaxIdMandatory: boolean;
  isMemberWithSavedPaymentMethods: boolean;
}

export type PaywallCheckoutPreviewMutationResult =
  | UseMutationResult<PaywallCheckoutPreview, Error, any>
  | Record<string, never>;

export interface UsePaywallCheckoutReturn {
  applyCouponCode: (couponCode?: string) => void;
  clearCouponCode: () => void;
  enableTaxIdCollection: () => void;
  getSelectedPrice: () => any;
  isTaxIdCollectionEnabled: boolean;
  paymentError: PaymentError;
  previewMutation: PaywallCheckoutPreviewMutationResult;
  setMemberAddressAttributes: (event: any) => void;
  setPaymentError: Dispatch<SetStateAction<PaymentError>>;
  setSelectedPrice: (priceId: any) => void;
  isCardInfoComplete: boolean;
  setIsCardInfoComplete: (arg: boolean) => void;
  isCardInfoValid: boolean;
  isPaywallActive: boolean;
  isCardInfoRequired: boolean;
  isSetupIntentRequired: boolean;
  arePostCreateActionsRequired: boolean;
  handleError: (param: any) => void;
  isProcessingPayment: boolean;
  setIsProcessingPayment: (arg: boolean) => void;
  isBuyNowPayLaterAllowed: boolean;
  setIsBuyNowPayLaterAllowed: (arg: boolean) => void;
  setStripePaymentMethodType: (arg: string) => void;
  stripePaymentMethodType: string;
  amountDue: number;
  isMemberUsingSavedPaymentMethod: boolean;
  setIsMemberUsingSavedPaymentMethod: (arg: boolean) => void;
  paywallFailure: PaywallFailure | null;
  setPaywallFailure: Dispatch<SetStateAction<PaywallFailure | null>>;
}

export const usePaywallCheckout = ({
  isMemberAddressRequired,
  isTaxIdMandatory,
  paywall,
  isMemberWithSavedPaymentMethods,
}: UsePaywallCheckoutProps): UsePaywallCheckoutReturn => {
  const { watch, setValue, setError } = useFormContext();

  const [isMemberUsingSavedPaymentMethod, setIsMemberUsingSavedPaymentMethod] =
    useState(false);

  const [stripePaymentMethodType, setStripePaymentMethodType] =
    useState("card");
  const [isCardInfoComplete, setIsCardInfoComplete] = useState(false);
  const [paymentError, setPaymentError] = useState(initialPaymentError);
  const [paywallFailure, setPaywallFailure] = useState<PaywallFailure | null>(
    null,
  );
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [isBuyNowPayLaterAllowed, setIsBuyNowPayLaterAllowed] = useState(true);
  const [amountDue, setAmountDue] = useState(0);
  const [isFreeForever, setIsFreeForever] = useState(false);

  useEffect(() => {
    if (paymentError.existingAccount) {
      setError("email", { type: "duplicated" });
    }
  }, [setError, paymentError]);

  const previewMutation = useMutation<any, Error, any>(params =>
    paywallCheckoutApi.preview(params),
  );

  const { paywall_price_id, payment_method_type } = watch();

  const previewCheckout = useCallback(() => {
    const {
      community_id,
      paywall_price_id,
      community_member_billing_info_attributes,
      coupon_code,
      coupon_code_applied,
      payment_method_type,
    } = watch();

    const params = {
      queryParams: {
        community_id,
        paywall_price_id,
        community_member_billing_info_attributes,
        coupon_code,
        coupon_code_applied,
      },
      shouldIgnoreCommunityMember: isPix(payment_method_type),
      locale: getCurrentCircleLocale(),
    };
    previewMutation.mutate(params, {
      onSuccess: preview => {
        setPaymentError(initialPaymentError);
        setAmountDue(preview.amount_due_now);
        setIsFreeForever(preview.free_forever);
      },
      onError: error => {
        console.error(error);
        if (coupon_code_applied) {
          setValue("coupon_code_applied", false);
        }
        setPaymentError({
          message: error.message,
          type: "danger",
          disablePayButton: true,
        });
      },
    });
  }, [setValue, setPaymentError, previewMutation]);

  useEffect(() => {
    previewCheckout();
  }, [paywall_price_id, payment_method_type]);

  const setSelectedPrice = useCallback(
    priceId => {
      setValue("paywall_price_id", priceId);
    },
    [setValue, previewCheckout],
  );

  const getSelectedPrice = useCallback(() => {
    const priceId = watch("paywall_price_id");
    if (!priceId) {
      return { trial_enabled: false };
    }
    return paywall.prices.find((price: any) => price.id == priceId);
  }, [watch, paywall.prices]);

  const setMemberAddressAttributes = useMemberAddressAttributes(
    watch,
    setValue,
    isMemberAddressRequired,
    previewCheckout,
  );

  const applyCouponCode = useCallback(
    couponCode => {
      const code = couponCode || watch().coupon_code || "";
      setValue("coupon_code", code.toUpperCase());
      setValue("coupon_code_applied", true);
      previewCheckout();
    },
    [watch, setValue, previewCheckout],
  );

  const clearCouponCode = useCallback(() => {
    setValue("coupon_code", "");
    setValue("coupon_code_applied", false);
    setIsFreeForever(false);
    setIsBuyNowPayLaterAllowed(true);
    previewCheckout();
  }, [setValue, previewCheckout]);

  const [isTaxIdCollectionEnabled, setTaxIdCollectionEnabled] =
    useState(isTaxIdMandatory);

  const enableTaxIdCollection = useCallback(
    () => setTaxIdCollectionEnabled(true),
    [setTaxIdCollectionEnabled],
  );

  const [taxIdDebounceTimer, setTaxIdDebounceTimer] = useState<any>();
  const clearTaxIdDebounceTimer = useCallback(() => {
    if (taxIdDebounceTimer) {
      clearTimeout(taxIdDebounceTimer);
      setTaxIdDebounceTimer(undefined);
    }
  }, [taxIdDebounceTimer, setTaxIdDebounceTimer]);
  useEffect(() => {
    const debouncePreviewCheckout = () => {
      clearTaxIdDebounceTimer();
      setTaxIdDebounceTimer(
        setTimeout(() => {
          previewCheckout();
        }, 250),
      );
    };

    const taxIdChangesSub = watch((values, { name }) => {
      if (name === "community_member_billing_info_attributes[tax_id_value]") {
        const taxIdEnum =
          values["community_member_billing_info_attributes"]["tax_id_type"];
        const taxIdValue =
          values["community_member_billing_info_attributes"]["tax_id_value"];
        if (isValidTaxIdValue(taxIdEnum, taxIdValue)) {
          debouncePreviewCheckout();
        }
      }
    });

    return () => {
      taxIdChangesSub.unsubscribe();
      clearTaxIdDebounceTimer();
    };
  }, [watch, previewCheckout, clearTaxIdDebounceTimer, setTaxIdDebounceTimer]);

  const selectedPrice = getSelectedPrice();
  const isPaywallActive = isActive(paywall);

  const {
    isCardInfoRequired,
    isCardInfoValid,
    isSetupIntentRequired,
    arePostCreateActionsRequired,
  } = useCardInfo(
    paywall,
    selectedPrice,
    payment_method_type,
    isCardInfoComplete,
    isMemberWithSavedPaymentMethods,
    isMemberUsingSavedPaymentMethod,
    isFreeForever,
  );

  const handleError = (options: any) => {
    setPaymentError({ ...DEFAULT_ERROR, ...options });
  };

  return {
    applyCouponCode,
    clearCouponCode,
    enableTaxIdCollection,
    getSelectedPrice,
    isTaxIdCollectionEnabled,
    paymentError,
    previewMutation,
    setMemberAddressAttributes,
    setPaymentError,
    setSelectedPrice,
    isCardInfoComplete,
    setIsCardInfoComplete,
    isCardInfoValid,
    isPaywallActive,
    isCardInfoRequired,
    isSetupIntentRequired,
    arePostCreateActionsRequired,
    handleError,
    isProcessingPayment,
    setIsProcessingPayment,
    amountDue,
    stripePaymentMethodType,
    setStripePaymentMethodType,
    isMemberUsingSavedPaymentMethod,
    setIsMemberUsingSavedPaymentMethod,
    paywallFailure,
    setPaywallFailure,
    isBuyNowPayLaterAllowed,
    setIsBuyNowPayLaterAllowed,
  };
};
